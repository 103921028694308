@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-color:#0E0E15;
    --second-color: #1A1820;
    --light-blue:#BEC3FF;
    --violet:#A8A6FF;
    --light-violet:#8E94F2;
    --main-font: Inter, Arial, sans-serif;
    --second-font:"Segoe UI", Arial, sans-serif;
    --third-font:"Inter Tight", sans-serif;
}

.footer{
    margin-top: 7em;
    position: relative;
    height: 100vh;
    overflow-x: hidden;
    background-color: var(--light-blue);
    display: flex;
    align-items: end;
    justify-content: center;
}

.footer-title{
    color: black;
    margin-top: 20px;
    font-size: 2.6em;
}

.footer-container{
    position: relative;
    background-color: transparent;
    width: 90vw;
    height: 70vh;
    margin-bottom: 50px;
    display: flex;
}

.footer-details{
    display: grid;
    grid-template-columns: repeat(2,500px);
    height: max-content;
}

.footer-item{
    width: 30vw;
    height: 100px;
    background-color: transparent;
    margin: 5px;
}

.info,.second-info{
    font-family: var(--third-font);
}

.second-info{
    font-size: 2.3em;
}

.footer-button,.footer-button button{
    width: 80%;
    margin: 0 auto;
}

.footer-button button{
    height: 45px;
    border-radius: 20px;
    background-color: var(--main-color);
    border: 1px solid var(--light-violet);
    color: white;
    font-size: 1.1em;
    margin-top: 10px;
}

.footer-social__container{
    width: 30vw;
    height: max-content;
    background-color: transparent;
    position: relative;
    top: 30vh;
}

.media-btn button{
    width: 100px;
    padding: 10px;
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid black;
}

.media-btn button:nth-child(2){
    background-color: var(--light-violet);
}

.media-btn button:nth-child(3){
    background-color: var(--violet);
}

.division-line{
    position: absolute;
    height: 2px;
    width: 97%;
    left: 15px;
    background-color: var(--light-violet);
    bottom: 150px;
}

.rights-container{
    position: absolute;
    bottom: 70px;
    left: 30px;
    font-family: var(--third-font);
    display: grid;
    grid-template-columns: repeat(3,200px);
}

.address{
    width: 600px;
}

.responsive-media{
    display: none;
}

.none{
    display: none;
}

@media (max-width: 1230px) {
    .footer-container{
        position: relative;
        display: flex;
        align-items: start;
    }

    .footer-details{
        display: grid;
        grid-template-columns: repeat(1,500px);
        height: max-content;
        position: relative;
        background-color: transparent;
    }

    .division-line{
        display: none;
    }

    .footer-social__container{
        display: none;
    }

    .media-btn button{
        width: 100px;
        padding: 10px;
    }

    .media-btn{
        width: 100vw;
    }

    .rights-container{
        position: absolute;
        bottom: 5px;
        left: 30px;
    }

    .responsive-item{
        display: block;
    }

    .footer-title h1{
        font-size: 1.5em;
    }
}

@media (max-width: 1000px) {
    .footer{
        height: 120vh;
    }

    .footer-item{
        width: 100vw;
        height: 100px;
        background-color: transparent;
        margin: 5px;
    }

    .this-item{
        width: 70vw;
    }

    .footer-item .second-info{
        font-size: 1.3em;
    }

    .address{
        width:300px;
    }

    .info{
        font-size: 1.8em;
    }
    .footer-details{
        position: relative;
        bottom: 140px;
    }

    .footer-title{
        font-size: 1.8em;
    }

    .rights-container{
        display: grid;
        grid-template-columns: repeat(2,150px);
    }
}

@media(max-width: 500px){
    .rights-container{
        position: absolute;
        bottom: -50px;
        left: 0;
    }

    .info{
        font-size: 1.4em;
    }

    .footer{
        height: 130vh;
    }

    .footer-details{
        position: relative;
        bottom: 180px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-color:#0E0E15;
    --second-color: #1A1820;
    --light-blue:#BEC3FF;
    --light-violet:#8E94F2;
    --main-font: Inter, Arial, sans-serif;
    --second-font:"Segoe UI", Arial, sans-serif;
    --third-font:"Inter Tight", sans-serif;
}

.our-team{
    position: relative;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
}

.our-team .title{
    position: absolute;
    height: 130px;
    top: 20px;
    left: 30px;
    font-size: 1.4em;
    text-transform: uppercase;
    font-family: var(--third-font);
    color: var(--light-blue);
    display: flex;
    justify-content: space-between;
    width: 94vw;
}

.our-team h6{
    color: var(--light-blue);
    width: 70%;
    height: 5px;
}

.our-team span{
    color:white;
}

.slider{
    display: flex;
    overflow: hidden;
    margin-top: 150px;
}

.slide-wrapper{
    display: flex;
    white-space: nowrap;
    animation: slide 25s infinite linear;
}

.slide-item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30vw;
    height: 60vh;
    border-radius: 20px;
    margin: 10px;
}

.slide-item img{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

@keyframes slide {
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}

@media (max-width: 1300px) {
    .slide-item{
        width: 45vw;
        height: 60vh;
    }
}

@media (max-width: 1000px) {
    .slide-item{
        width: 50vw;
    }
}

@media (max-width: 700px) {
    .slide-item{
        width: 80vw;
        height: 50vh;
    }
}

@media (max-width: 670px) {
    .our-team .title h1{
        font-size: 1.2em;
        width: 90vw;
    }

    .title svg{
        display: none;
    }
}

@media (max-width: 500px) {
    .slide-item{
        width: 95vw;
        height: 50vh;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button{
    cursor: pointer;
}

:root{
    --main-color:#0E0E15;
    --second-color: #1A1820;
    --light-blue:#BEC3FF;
    --light-violet:#8E94F2;
    --main-font: Inter, Arial, sans-serif;
    --second-font:"Segoe UI", Arial, sans-serif;
    --third-font:"Inter Tight", sans-serif;
}

.services{
    position: relative;
    height: 200vh;
    background-color: var(--second-color);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title{
    position: absolute;
    top: 20px;
    left: 30px;
    font-size: 1.6em;
    text-transform: uppercase;
    font-family: var(--third-font);
    color: var(--light-blue);
    display: flex;
    justify-content: space-between;
    width: 94vw;
}

.container-cards{
    display: grid;
    grid-template-columns: repeat(2,auto);
    position: absolute;
    margin-top: 40px;
    width: max-content;
}

.card{
    width: 43vw;
    position: relative;
    border-radius: 20px;
    height: 280px;
    margin: 10px;
    background-color: var(--light-blue);
}

.card > *{
    font-family: var(--third-font);
}

.card:nth-child(2),.card:nth-child(3),.card:nth-child(6),.card:nth-child(7){
    background-color: var(--light-violet);
}

.light-violet{
    background-color: var(--light-violet);
}

.card-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    font-family: var(--third-font);
}

.card-title h1{
    font-size: 1.8em;
    font-weight:inherit;
}

.description {
    padding: 20px;
    margin-top: 80px;
    width: 80%;
}

.services-btn{
    position: absolute;
    bottom: 30px;
}

.services-btn button{
    width: 250px;
    height: 50px;
    border-radius: 40px;
    border: 3px solid black;
    background-color: var(--light-blue);
    text-transform: uppercase;
}

@media (max-width: 1015px) {
    .container-cards{
        grid-template-columns: repeat(1,auto);
    }

    .card{
        width: 60vw;
        height: 230px;
    }

    .card-title h1{
        font-size: 2.5em;
        font-weight:inherit;
    }

    .description p{
        font-size: 1.2em;
    }

    .description {
        padding: 20px;
        margin-top: 60px;
        width: 80%;
    }
}

@media (max-width: 715px) {
    .description{
        display: none;
    }
    .card{
        height: 150px;
        width: 90vw;
    }

    .services{
        height: 210vh;
    }

    .card-title h1{
        font-size: 2em;
        font-weight:inherit;
    }
}
:root{
    --second-color: #1A1820;
    --main-font: Inter, Arial, sans-serif;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

a{
    color: white;
}

nav{
    height: 100px;
    background-color: var(--second-color);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
}

ul,.logo{
    display: flex;
    font-family: var(--main-font);
}

nav ul, nav li,nav .logo{
    cursor: pointer;
}

ul li,.logo{
    margin: 10px;
    font-size: 18px;
}

.icons li{
    position: relative;
    top: 5px;
    margin: 2px;
}

.hamburger-react{
    display: none;
}

.responsive-navbar__items{
    display: none;
}

@media (max-width: 800px) {

    .hamburger-react{
        display: block;
    }
    .navbar-items,.icons{
        display: none;
    }

    .responsive-navbar__items{
        position: absolute;
        background-color: var(--second-color);
        z-index: 5;
        width: 100%;
        margin: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction:column;
        top: 100px;
    }

    .responsive-navbar__items li{
        font-size: 1.5em;
    }

    .navbar-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-color:#0E0E15;
    --second-color: #1A1820;
    --light-blue:#BEC3FF;
    --light-violet:#8E94F2;
    --main-font: Inter, Arial, sans-serif;
    --second-font:"Segoe UI", Arial, sans-serif;
    --third-font:"Inter Tight", sans-serif;
}

.projects{
    margin-top: 100px;
    background-color: var(--second-color);
    position: relative;
    height: 110vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title{
    position: absolute;
    height: 80px;
    top: 20px;
    left: 30px;
    font-size: 1.6em;
    text-transform: uppercase;
    font-family: var(--third-font);
    color: var(--light-blue);
    display: flex;
    justify-content: space-between;
    width: 94vw;
}

.title h6{
    position: absolute;
    bottom: 0;
    left: 0;
    font-family:var(--second-font);
    font-size: 0.6em;
    color: white;
}

h6 span{
    color: var(--light-blue);
}

.grid-container{
    margin-top: 80px;
    display: grid;
    grid-template-columns: 400px auto;
    grid-auto-rows: minmax(550px, auto);
    grid-gap: 20px;
    width: 60vw;
}

.element{
    position: relative;
    border-radius: 20px;
    height: 500px;
}

.element:nth-child(1){
    grid-column: 1 / -1;
}

.project-buttons{
    width: max-content;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.project-buttons svg{
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    height: 70px;
    border: none;
    width: 70px;
}

.project-item{
    font-size: 1.6em;
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: var(--light-blue);
    width: 170px;
    height: 70px;
}

.element button{
    cursor: pointer;
}

@media (max-width: 1010px) {
    .grid-container{
        width: 80vw;
    }

    .element{
        height: 550px;
    }
}

@media (max-width: 650px) {
    .grid-container{
        width: 80vw;
        margin-left: 10px;
    }

    .element{
        height: 450px;
    }

    .projects{
        justify-content: start;
    }
}

@media (max-width: 520px) {
    .grid-container{
        width: 100px;
        margin-left: 5px;
    }

    .element{
        height: 450px;
        width: 350px;
    }

    .projects{
        justify-content: start;
    }

    .project-item{
        font-size: 1.3em;
        padding: 10px;
        border: none;
        border-radius: 20px;
        background-color: var(--light-blue);
        width: 130px;
        height: 70px;
    }


    .title{
        font-size: 1.2em;
    }

    .title h6{
        position: absolute;
        bottom: 0;
        left: 0;
        font-family:var(--second-font);
        font-size: 0.6em;
        color: white;
    }
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-color:#0E0E15;
    --second-color: #1A1820;
    --light-blue:#BEC3FF;
    --light-violet:#8E94F2;
    --main-font: Inter, Arial, sans-serif;
    --second-font:"Segoe UI", Arial, sans-serif;;
}

.home{
    height: 100vh;
}

body{
    background-color: var(--main-color);
}

.container{
    height: 80vh;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: var(--second-color);
    width: 90vw;
    border-radius: 50px;
}

.welcome{
    font-size: 3em;
    color: var(--light-blue);
    font-family: var(--third-font);
    position: relative;
    font-weight: bold;
}


.container p {
    color: white;
    font-family: "Segoe UI", Arial, sans-serif;
}

.welcome-text{
    color: var(--light-violet);
    position: relative;
    right: 10px;
}

.bottom-text{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.bottom-text h3{
    text-transform: uppercase;
    font-family: var(--second-font);
    width: 60vw;
    text-align: center;
    font-size: 1.3em;
}

.to-right{
    position: relative;
    left: 14px;
}

.main-button , .second-button{
    margin: 10px;
    width: 185px;
    text-transform: uppercase;
    border-radius: 50px;
    font-size: 16px;
    height: 60px;
}

.main-button{
    background-color: var(--second-color);
    border: none;
    color: white;
    box-shadow: 0 0 3px white,
                0 0 5px #BB9BFF0D;
}

.second-button{
    background-color:var(--light-blue);
    border: 2px solid black;
}

.buttons{
    display: flex;
}

.bottom-text{
    color: var(--light-blue);
}

.bottom-text span{
    color: white;
}

@media (max-width: 1475px) {
    .welcome{
        font-size: 2.5em;
        margin-bottom: 10px;
    }

    .bottom-text h3{
        text-align: start;
        font-size: 1.3em;
    }

    .welcome,.bottom-text h3,.buttons{
        margin:20px 50px;
    }

    .container{
        align-items: start;
    }
}

@media (max-width: 1125px) {
    .welcome{
        font-size: 2.5em;
        margin-bottom: 10px;
    }

    .welcome,.bottom-text h3,.buttons{
        margin:30px 50px;
    }


    .welcome{
        font-size: 2em;
        margin-bottom: 30px;
    }


    .bottom-text h3{
        text-align: start;
        font-size: 1.1em;
    }
}

@media (max-width: 850px) {
    .welcome{
        font-size: 2.5em;
        margin-bottom: 10px;
    }

    .welcome,.bottom-text h3,.buttons{
        margin:40px 50px;
    }

    .bottom-text{
        margin-top: 40px;
    }

    .bottom-text h3{
        text-align: start;
        font-size: 1em;
    }

    .welcome{
        font-size: 1.5em;
        margin-bottom: 30px;
    }

    .welcome-text{
        margin-left: 10px;
    }

    .main-button{
        display: none;
    }
    .container{
        width: 100vw;
    }
}

@media (max-width: 610px){
    .main-title{
        display: flex;
        flex-direction: column;
    }

    .buttons{
        margin-top: 70px;
    }

    .home{
        height: 120vh;
    }
    .container{
        position: absolute;
        top: 62%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-color:#0E0E15;
    --second-color: #1A1820;
    --light-blue:#BEC3FF;
    --light-violet:#8E94F2;
    --main-font: Inter, Arial, sans-serif;
    --second-font:"Segoe UI", Arial, sans-serif;
    --third-font:"Inter Tight", sans-serif;
}

.blog{
    position: relative;
    height: 170vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-color);
    overflow-x: hidden;
}

.articles-path{
    margin: 20px;
    font-family: var(--third-font);
    position: absolute;
    top: 30px;
    left: 30px;
}

.articles-path h5{
    color: gray;
}

.articles-path span{
    color: var(--light-blue);
    opacity: 1;
}

.blog .title{
    position: absolute;
    height: 60px;
    top: 20px;
    left: 30px;
    font-size: 1.4em;
    text-transform: uppercase;
    font-family: var(--third-font);
    color: var(--light-blue);
    display: flex;
    justify-content: space-between;
    width: 94vw;
}

.title h6{
    position: absolute;
    bottom: 0;
    left: 0;
    font-family:var(--second-font);
    font-size: 0.6em;
    color: white;
}

.articles-title{
    position: absolute;
    top: 100px;
    left: 40px;
    font-size: 1.6em;
    text-transform: uppercase;
    font-family: var(--third-font);
    color: var(--light-blue);
    display: flex;
    justify-content: space-between;
    width: 94vw;
}

h6 span{
    color: var(--light-blue);
}

.input-content{
    width: 100vw;
}

.input-content > *{
    border-radius: 20px;
    padding: 10px;
    border: transparent;
    background-color: var(--second-color);
    margin: 5px;
    color: var(--light-violet);
}

.input-content input{
    width: 30%;
}

.input-content input::placeholder{
    color: var(--light-violet);
}

.input-content select{
    width: 23%;
}

.blog-items{
    background-color: transparent;
    margin-top: 180px;
}

.blog-container{
    width: 90vw;
    height: 88vh;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--light-violet);
}

.blog-buttons{
    position: relative;
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px;
    gap: 7px;
}

.blog-buttons button{
    padding: 5px;
    border-radius: 20px;
    background-color: transparent;
    width: 85px;
    border: 1px solid var(--light-violet);
    color: gray;
}

.card-container{
    width: 97%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3,auto);
}

.card-item,.card-item2{
    background-color: var(--main-color);
    position: relative;
    border-radius: 20px;
    padding: 10px;
    margin: auto;
    box-shadow: 0 0 2px var(--light-blue);
}

.card-item{
    height: 50vh;
    background: var(--second-color);
    margin: 5px;
}

.profile{
    background-color: transparent;
    width: 100%;
    height: 40px;
    display: flex;
}

.profile-items {
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.profile li{
    font-size: 12px;
    margin: 2px;
}

.profile-name,.profile-profession{
    position: relative;
    left: 10px;
}

.profile-name{
    font-weight: bold;
}

.profile-profession{
    color: var(--light-blue);
}

.image{
    position: relative;
    width: 150px;
}

.profile-image img{
    border-radius: 100%;
}

.news{
    align-items: end;
}

.card-image{
    top: 6px;
    position: relative;
    width: 100%;
    height: 183px;
}

.profile-card__title{
    position: relative;
    width: 90%;
    height: 65px;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    font-family: var(--third-font);
    color: var(--light-violet);
}

.profile-card__description{
    background-color: transparent;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    font-family: var(--third-font);
    color: var(--light-blue);
}

img{
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


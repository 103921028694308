@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-color:#0E0E15;
    --second-color: #1A1820;
    --light-blue:#BEC3FF;
    --light-violet:#8E94F2;
    --gray-color:#CFCFCF;
    --main-font: Inter, Arial, sans-serif;
    --second-font:"Segoe UI", Arial, sans-serif;
    --third-font:"Inter Tight", sans-serif;
}

.flex{
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-projects{
    overflow: hidden;
    position: relative;
    height: 200vh;
}

.projects-title{
    margin: 20px;
    font-family: var(--third-font);
}

.projects-title h5{
    color: gray;
}

.projects-title span{
    color: var(--light-blue);
    opacity: 1;
}


.main-container{
    position: relative;
    height: 170vh;
    background-color: var(--second-color);
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 100px;
}

.main-container .title{
    height: 180px;
}

.main-container .title h6{
    width: 35%;
}

.tab-navigation{
    position: relative;
    bottom: 50px;
    color: white;
}

.projects-cards__container{
    width: 100%;
    height: 68%;
    background-color: transparent;
    display: grid;
    grid-template-columns: repeat(3,auto);
    place-items: center;
    margin-bottom: 80px;
    gap: 5px;
}

.project-card{
    background-color: var(--light-blue);
    width: 480px;
    height: 280px;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 20px;
}

.project-items > *{
    font-family: var(--third-font);
    position: relative;
}

.project-items{
    margin-top: 10px;
    width: 95%;
}

.card-subtitle{
    position: relative;
    left: 20px;
}

.project-card__btn{
    position: relative;
    left: 20px;
    margin-top: 20px;
}

.project-card__btn button{
    width: 100px;
    height: 40px;
    border-radius: 20px;
    background-color: transparent;
    border:3px solid black;
}